import css from './styles/glider.css'
import Glider from 'glider-js'
import gliderAutoplay from './js/glider.autoplay';
const mainDiv = document.getElementById("surveys-me-reviews");
const slug = mainDiv.getAttribute('data-customer-unique-id');

async function fetchText() {
    let response = await fetch(`${process.env.APP_URL}/api/user/${slug}/reviews`);
    let reviews = await response.json();
    

    let slidesHTML = '';
    reviews.reviews.forEach(review => {        
        slidesHTML = slidesHTML + '<div class="slide" property="review" typeof="Review">' +
            '<div class="description">' +
                '<div class="icon">' +
                    '<i class="ion ion-star" style="color: #FFAD09"></i> ' +
                    '<i class="ion ion-star" style="color: #FFAD09"></i> ' +
                    '<i class="ion ion-star" style="color: #FFAD09"></i> ' +
                    '<i class="ion ion-star" style="color: #FFAD09"></i> ';
                    if (review.overall_rating == 5) {
                        slidesHTML = slidesHTML +  '<i class="ion ion-star" style="color: #FFAD09"></i> ';
                    } else {
                        slidesHTML = slidesHTML + '<i class="ion ion-ios-star-half" style="color: #DDDEDE"></i>';
                    }
                slidesHTML = slidesHTML + '</div>' +
                '<p property="reviewBody">'+review.review_text+'</p>' +
            '</div>';
            if (review.customer_first_name && review.customer_last_name) {
                slidesHTML = slidesHTML + '<span>'+review.customer_first_name + ' ' + review.customer_last_name.substr(0, 3) +'...</span>';
            }
            slidesHTML = slidesHTML + '</div>';
    });
    

    const html = '<div class="overall_rating" vocab="https://schema.org/" typeof="Place">' +
            '<h1 property="name">' + reviews.trading_name + '</h1>' +
            '<div class="d-flex" property="aggregateRating" typeof="AggregateRating">' +
                '<h3><span property="ratingValue">'+reviews.overall_rating+'</span>/5</h3>' +
                '<div class="icon">' +
                    '<i class="ion ' + (reviews.overall_rating >= 0.5 ?  'ion-star' : 'ion-ios-star-half') + '" style="color:  #FFAD09"></i>' +
                    '<i class="ion ' + (reviews.overall_rating >= 1.5 ?  'ion-star' : 'ion-ios-star-half') + '" style="color:  ' + (reviews.overall_rating > 1 ?  '#FFAD09' : '#DDDEDE') + '"></i>' +
                    '<i class="ion ' + (reviews.overall_rating >= 2.5 ?  'ion-star' : 'ion-ios-star-half') + '" style="color:  ' + (reviews.overall_rating > 2 ?  '#FFAD09' : '#DDDEDE') + '"></i>' +
                    '<i class="ion ' + (reviews.overall_rating >= 3.5 ?  'ion-star' : 'ion-ios-star-half') + '" style="color:  ' + (reviews.overall_rating > 3 ?  '#FFAD09' : '#DDDEDE') + '"></i>' +
                    '<i class="ion ' + (reviews.overall_rating >= 4.5 ?  'ion-star' : 'ion-ios-star-half') + '" style="color:  ' + (reviews.overall_rating > 4 ?  '#FFAD09' : '#DDDEDE') + '"></i>' +                
                '</div>' +
                '<p>' +
                    '<b>Sentiment Score</b> From <span property="reviewCount">'+reviews.count+'</span> Surveys' +
                '</p>' +
            '</div>' +
        '</div>' +
        '<div class="glider-contain">' +
            '<h2>What our clients say</h2>' +
            '<div id="slides" class="glider slides">' +        
                slidesHTML +
            '</div>' +
            '<button class="glider-prev">&laquo;</button>' +
            '<button class="glider-next">&raquo;</button>' +
            '<div role="tablist" class="dots"></div>' +
            '<div class="d-flex copyright">Powered by <img width="100px" src="https://app.surveysme.io/img/logo.png" alt="SurveysMe Logo">.</div>' +
        '</div>' +
    '</div>';

mainDiv.innerHTML = html;

gliderAutoplay(
    new Glider(document.getElementById("slides"), {
        slidesToShow: 3,
        slidesToScroll: 1,
        itemWidth: 300,
        draggable: true,
        scrollLock: true,
        draggable: true,
        dots: false,
        //dots: ".dots",
        arrows: {
            prev: '.glider-prev',
            next: '.glider-next'
        },
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToScroll: 1,
                    itemWidth: 400,
                    slidesToShow: 3,
                    exactWidth: true
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToScroll: 1,
                    itemWidth: 300,
                    slidesToShow: 3,
                    exactWidth: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToScroll: 1,
                    itemWidth: 350,
                    slidesToShow: 2,
                    exactWidth: true
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                }
            }
        ]
    })
    ,
    {
        interval: 5000,
        onRestart: function () {            
        },
    }
);

}


fetchText();




