export default function(n, t) {
    n.autoplay = function(n) {
        var t = n.interval,
            e = n.startItem,
            o = void 0 === e ? 0 : e,
            u = n.pausable,
            i = void 0 === u || u,
            a = n.onPause,
            r = n.onRestart,
            l = this;
        l.currentItem = o - 1;
        var s = function() {
            l.currentItem < l.slides.length - l.opt.slidesToShow ? l.currentItem++ : l.currentItem = 0, l.scrollItem(l.currentItem)
        };
        l.autoplayInterval = setInterval(s.bind(l), t), l.isRunning = !0;
        var c = function() {
                clearInterval(l.autoplayInterval), l.isRunning = !1, a && "function" == typeof a && a()
            },
            d = function() {
                l.isRunning || (l.currentItem = l.slide, l.autoplayInterval = setInterval(s.bind(l), t), l.isRunning = !0, r && "function" == typeof r && r())
            };
        i && (l.ele.onmouseover = c.bind(l), l.ele.ontouchstart = c.bind(l), l.ele.onmouseout = d.bind(l), l.ele.ontouchend = d.bind(l), l.ele.ontouchcancel = d.bind(l)), l.pause = c, l.run = d
    }, n.autoplay(t)
};